/**
 * Created by Hp on 04/05/2017.
 */
(function () {
    'use strict';

    var ContractHistoricDialog = {
        controller: ContractHistoricDialogCtrl,
        controllerAs: "vm",
        template: require("billing/views/contract-historic.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true
    };

    ContractHistoricDialogCtrl.$inject = ["$mdDialog", "$translate", "billingService", "$state"];

    function ContractHistoricDialogCtrl($mdDialog, $translate, billingService, $state) {
        let vm = this;
        vm.$onInit = init;

        vm.payInstance = payInstance;
        vm.billInstance = billInstance;
        vm.onPaginate = onPaginate;
        vm.onDetailsPaginate = onDetailsPaginate;
        vm.showDetails = showDetails;
        vm.getAmount = getAmount;
        vm.getSubTotal = getSubTotal;
        vm.submit = submit;
        vm.checkChanged = checkChanged;
        vm.selectArticle = selectArticle;
        vm.removeLine = removeLine;
        vm.removeInstance = removeInstance;
        vm.cancel = cancel;
        vm.fenceInstancePayment = fenceInstancePayment;

        vm.instances = [];
        vm.query = {
            limit: 10,
            page: 1,
            order: "-end_at"
        }
        vm.detailsQuery = {
            limit: 10,
            page: 1,
            order: "-end_at"
        }

        vm.paginationLabel = {
            page: $translate['instant']('page'),
            rowsPerPage: $translate['instant']('rowsPerPage'),
            of: $translate['instant']('of')
        }

        vm.options = [10, 20, 100];
        vm.selectedInstance = null;
        vm.handleReload = false;

        function init() {

            getData();
        }


        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            // getData();
        }

        function onDetailsPaginate(page, limit) {
            vm.detailsQuery = _.assign(vm.detailsQuery, {page: page, limit: limit});
        }


        function getData(current_instance = null) {
            vm.promise = billingService.getContractInstances(vm.instanceParent).then(success);

            function success(data) {
                if (current_instance) {
                    vm.parentCurrentInstance = current_instance;
                }
                vm.instances = data;
            }
        }

        function payInstance(obj) {
            billingService.payContract(_.assign(obj.parent, {amount_to_pay: obj.amount_to_pay}));
            $mdDialog.hide();

        }

        function billInstance(obj) {
            $state.go("app.billing.invoice-form", {
                invoiceId: null,
                visits: [_.assign(obj.parent, {current_instance: obj})],
                beneficiary: obj.parent.beneficiary,
                beneficiaryType: 'PATIENT',
                isContract: true,
                invoiceDate: null
            });
            $mdDialog.hide();
        }

        function showDetails(instance, $event) {
            $event.stopPropagation();
            if (vm.selectedInstance == instance.id) {
                vm.selectedInstance = -1;
            } else {
                vm.selectedInstance = instance.id;
            }
        }

        function getAmount(instance) {
            return _.get(instance, 'details') ? _.sumBy(instance.details, function (line) {
                return line.sub_total;
            }) : 0;
        }

        function getSubTotal(line) {
            return line.sub_total = _.get(line, 'price', 0) * _.get(line, 'qte', 0);
        }

        function submit(instance, event) {
            vm.promise = billingService.updateContractInstance(instance.id, {
                details: instance.details,
                taxed_amount: getAmount(instance)
            }).then((data) => {
                // instance=data;
                getData();
            });

        }

        function checkChanged(instance) {

        }

        function selectArticle(instance, ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then((data) => {
                _.forEach(data, function (article) {
                    if (_.isNil(_.find(instance.details, {code: article.code}))) {
                        instance.details.push({
                            code: article.code,
                            description: article.short_desc,
                            qte: 1,
                            price: _.get(article, 'sale_price', 0),
                            article: _.cloneDeep(article)
                        });
                    }
                });
            });
        }

        function removeLine(instance, index) {
            instance.details.splice((vm.detailsQuery.page - 1) * vm.detailsQuery.limit + index, 1);
        }

        function removeInstance(instance) {
            billingService.removeContractInstance(vm.instanceParent, instance).then(data => {
                if (data) {
                    billingService.deleteContractInstance(instance).then((res) => {
                        getData(data);
                        vm.handleReload = true;
                    });
                }
            })
        }

        function cancel() {
            $mdDialog.hide(vm.handleReload);
        }

        function fenceInstancePayment(instance, ev) {
            vm.promise = billingService.updateContractInstance(instance.id, {
                fenced_payment: true,
                fenced_amount: instance.remaining_amount
            }).then((data) => {
                // instance=data;
                vm.handleReload = true;
                getData();
            });

        }

    }

    module.exports = ContractHistoricDialog;

})();