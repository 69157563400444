(function () {
    'use strict';

    class CamCtrl {
        constructor($state, $mdToast, $translate, printService, configService) {
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.configService = configService;
        }

        static get $inject() {
            return ["$state", "$mdToast", "$translate", "printService", "configService"];
        }

        $onInit() {
        }

    }

    module.exports = CamCtrl;

})()