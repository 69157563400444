(function () {
    'use strict';

    class EquipmentFormCtrl {
        constructor($state, $mdToast, $translate, printService, equipmentService, $stateParams, $mdDialog) {
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.equipmentService = equipmentService;
            this.$mdToast = $mdToast;
            this.$stateParams = $stateParams;
            this.$mdDialog = $mdDialog;

            this.currentModel = this.$stateParams['modelId'];

        }

        static get $inject() {
            return ["$state", "$mdToast", "$translate", "printService", "equipmentService", "$stateParams", "$mdDialog"];
        }

        $onInit() {
            this.file = null;
            this.prevent_save = false;
            this.sparePartsQuery = {
                limit: 5,
                page: 1,
                order: "+code"
            };
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
            if (_.isNil(this.currentModel)) {
                this.promise = null;
                this.model = {spare_parts: []};
            } else {
                this.promise = this.equipmentService.getEquipment(this.currentModel).then((data) => {
                    this.model = data;
                });
            }
        }

        submit(with_cancel = false) {
            if (!this.prevent_save) {
                this.prevent_save = true;
                this.equipmentService.saveEquipment(this.model, this.file).then((data) => {
                    this.save_success(data, with_cancel);
                }, (err) => {
                    this.prevent_save = false
                });
            }
        }

        save_success(data, with_cancel) {
            this.prevent_save = false;
            if (with_cancel) this.$state.go('app.cam.equipment');
            else {
                this.model = data;
                this.$state.go('app.cam.equipment-form', {modelId: data.id}, {replace: true});
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant(
                        _.isNil(this.currentModel) ? this.$translate.instant('save_success') : this.$translate.instant('edit_success')))
                    .position("bottom left")
                    .hideDelay(1500));
            }
        }

        delete() {
            this.promise = this.equipmentService.deleteEquipment(this.currentModel).then((data) => {
                this.$state.go('app.cam.equipment');
            }, (err) => {
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant(this.$translate.instant('impossible_delete'))
                        .position("bottom left")
                        .hideDelay(1500)));
            });
        }

        onPaginateSpareParts(page, limit) {
            this.sparePartsQuery = _.assign(this.sparePartsQuery, {page: page, limit: limit});
            if (this.model.spare_parts.length < ((this.sparePartsQuery.page - 1) * this.sparePartsQuery.limit)) {
                this.sparePartsQuery.page = 1;
            }
        }

        removeItem(idx) {
            this.model.spare_parts.splice((this.sparePartsQuery.page - 1) * this.sparePartsQuery.limit + idx, 1);
        }

        selectSpareParts(ev) {
            this.$mdDialog.show(_.assign(require('cam/dialogs/spare-parts-list-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {is_deleted: {$ne: true}},
                }
            })).then(data => {
                this.model.spare_parts = _.concat(this.model.spare_parts, data);
            });
        }

        resetPicture(ev) {
            ev.stopPropagation();
            this.file = null;
            this.model.picture = null;
        }
    }

    module.exports = EquipmentFormCtrl;

})()