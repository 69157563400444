/**
 * Created by Hp on 03/05/2017.
 */
(function () {
    'use strict';
    module.exports = BillingCtrl;

    BillingCtrl.$inject = ["$state", "$q", "billingService", "$mdToast", "$translate", "printService", "$stateParams", "$mdDialog", "frontDeskService", "mnWebSocket", "configService", "$transitions", "sequenceService", "externalConnectionService"];

    function BillingCtrl($state, $q, billingService, $mdToast, $translate, printService, $stateParams, $mdDialog, frontDeskService, mnWebSocket, configService, $transitions, sequenceService, externalConnectionService) {
        var vm = this;
        var doc_type = $stateParams['type'];
        var resource = $stateParams['resource'];
        vm.$onInit = init;
        vm.toggle = false;
        vm.filterColumns = [];
        vm.filterQuery = {};
        vm.handleReload = false;
        vm.addClick = addClick;
        vm.handleSequenceChange = handleSequenceChange;
        vm.handleGuestDetails = handleGuestDetails;

        function init() {
            vm.filter = {is_deleted: {$ne: true}};
            vm.contractDefaultView = 'contract_default_list';
            vm.activeMembers = 0;
            vm.activeGuestMembers = 0;
            if (_.eq($state.$current['name'], "app.billing")) toState();
            let listener = $transitions.onBefore({}, toList);
            vm.sequenceModels = [];
            vm.sequenceModel = null;
            vm.exportHelpers = {
                translations: [
                    {key: 'future', value: $translate['instant']('future')},
                    {key: 'terminated', value: $translate['instant']('terminated')},
                    {key: 'stand_by', value: $translate['instant']('stand_by')},
                    {key: 'deactivated', value: $translate['instant']('deactivated')},
                    {key: 'expired', value: $translate['instant']('expired')},
                    {key: 'active', value: $translate['instant']('active')},
                    {key: 'draft', value: $translate['instant']('draft')},
                    {key: 'partial', value: $translate['instant']('partial')},
                    {key: 'unpaid', value: $translate['instant']('unpaid')},
                ]
            };

            let promises = [
                sequenceService.getSequenceModels({_model: handleModel(), _package: 'billing'}),
                sequenceService.getDefaultSequenceModels({_model: handleModel(), _package: 'billing'}),
                configService.get('billing_config'),
                mnWebSocket.call("billing.Contract.active_count_notify", {}),
                mnWebSocket.call("billing.Contract.active_guest_notify", {})
            ];
            vm.promise = $q.all(promises).then(data => {
                vm.sequenceModels = data[0];
                if (data[1]) {
                    vm.sequenceModel = data[1];
                    vm.filter['sequence_name'] = data[1];
                }
                vm.contractDefaultView = _.get(data[2], 'default_contract_view', 'contract_default_list');
                vm.activeMembers = data[3];
                vm.activeGuestMembers = data[4];
            });
            handleActions();

            mnWebSocket.sub("billing.Contract.list_notify", data => handleNotify(data));
            mnWebSocket.sub("billing.Contract.active_count_notify", data => {
                vm.activeMembers = data
            });
            mnWebSocket.sub("billing.Contract.active_guest_notify", data => {
                vm.activeGuestMembers = data;
            });

            function toList(transition) {
                let state = transition.to();
                if (_.eq(state.name, "app.billing")) {
                    toState();
                    return false;
                }
                return true;
            }

            function toState() {
                vm.promise = configService.get('billing_config')
                    .then(data => {
                        vm.defaultTab = _.get(data, 'defaultTab') == "contract_list" ? "app.billing.contract" : "app.billing.invoice"
                        $state.go(vm.defaultTab, {})
                    });
            }

            function handleNotify(data) {
                _.isFunction(vm.reset) ? vm.reset(null, true) : _.noop();
            }

        }

        function handleActions() {
            vm.flattenActions = {
                single: [
                    {
                        icon: 'mdi-checkbox-multiple-marked',
                        label: 'enter',
                        resource: 'contract-pointing',
                        action: 'create',
                        behavior: 'remove',
                        method: handlePointing,
                        disabled: (r) => {
                            return r.pointing_status.label === 'pointing_active'
                        },
                        class: "md-primary"

                    },
                    {
                        icon: 'mdi-checkbox-multiple-marked-outline',
                        label: 'exit',
                        resource: 'contract-pointing',
                        action: 'create',
                        behavior: 'remove',
                        method: handlePointing,
                        disabled: (r) => {
                            return r.pointing_status.label === 'pointing_inactive'
                        },
                        class: "md-warn"
                    },
                    {
                        icon: 'mdi-account-circle',
                        label: 'handle_pointing_guest_label',
                        resource: 'contract-guest',
                        action: 'get',
                        behavior: 'remove',
                        method: handleGuestPointing,
                        disabled: (r) => _.isNil(r.current_pointing),
                        class: "md-primary",
                        isStatic: true
                    },
                    {
                        icon: 'mdi-timetable',
                        label: 'handle_pointing_historic_label',
                        resource: 'contract-pointing',
                        action: 'get',
                        behavior: 'remove',
                        method: handlePointingHistoric,
                    }
                ],
                multiple: [

                    {
                        icon: 'mdi-checkbox-multiple-marked',
                        label: 'handle_pointing_label',
                        resource: 'contract-pointing',
                        action: 'create',
                        behavior: 'remove',
                        method: handlePointing,
                        class: "md-primary",
                    },
                    {
                        icon: 'mdi-view-stream',
                        label: 'contract_default_display',
                        resource: 'contract',
                        action: 'create',
                        behavior: 'remove',
                        method: handleView,
                        class: "md-primary",
                        isStatic: true
                    }
                ]
            }
            let multipleAction = [];
            let singleAction = [
                {
                    icon: 'mdi-pencil',
                    label: 'edition',
                    resource: resource,
                    action: 'create',
                    behavior: 'remove',
                    method: goToEdition
                },
                {
                    icon: 'mdi-printer',
                    label: 'print',
                    resource: resource,
                    action: 'create',
                    behavior: 'remove',
                    method: print
                }]
            if (doc_type === 'isInvoice') {
                singleAction.push({
                    icon: 'mdi-currency-usd',
                    label: 'paye',
                    resource: resource,
                    action: 'create',
                    behavior: 'remove',
                    condition: 'item.is_valid && !item.is_paid',
                    method: payeInvoice
                })
            }
            if (doc_type === 'isContract') {
                singleAction.push(
                    {
                        icon: 'mdi-card-account-details',
                        label: 'resume',
                        resource: 'patient',
                        action: 'get',
                        behavior: 'remove',
                        method: patientResume
                    },
                    {
                        icon: 'mdi-comment-text-outline',
                        label: 'comment',
                        resource: resource,
                        action: 'update',
                        behavior: 'disable',
                        method: addComment,
                        class: (row, ev) => {
                            return _.get(row, 'has_comment') ? {"md-primary": true} : {};
                        }
                    },
                    {
                        icon: 'mdi-history',
                        label: 'historic',
                        resource: resource,
                        action: 'create',
                        behavior: 'remove',
                        condition: 'item.is_valid',
                        method: contractHistoric
                    },
                    {
                        icon: 'mdi-receipt',
                        label: 'billing_visit',
                        resource: resource,
                        action: 'create',
                        behavior: 'remove',
                        condition: 'item.is_valid',
                        method: billContract
                    },
                    {
                        icon: 'mdi-currency-usd',
                        label: 'new_encasement',
                        resource: resource,
                        action: 'create',
                        behavior: 'remove',
                        condition: 'item.is_valid ', //&& !item[\'billing_details\'][\'canceled\']
                        method: billingService.payContract
                    }
                );
                multipleAction.push(
                    // {
                    //     icon: 'mdi-currency-usd',
                    //     label: 'paye',
                    //     resource: resource,
                    //     action: 'create',
                    //     behavior: 'disable',
                    //     condition: 'item.is_valid && !item.is_paid && !item[\'billing_details\'][\'canceled\']',
                    //     method: payContract
                    // },

                    {
                        icon: 'mdi-receipt',
                        label: 'billing_visit',
                        resource: resource,
                        action: 'create',
                        behavior: 'remove',
                        condition: 'item.is_valid',
                        method: billContract
                    },
                    {
                        icon: 'mdi-file-excel-box',
                        label: 'export_custom_xls',
                        resource: resource,
                        action: 'exportCustomXls',
                        behavior: 'remove',
                        method: exportCustomXls,
                        isStatic: true
                    },
                    {
                        icon: 'mdi-open-in-new',
                        label: 'renew',
                        resource: resource,
                        action: 'multiRenew',
                        behavior: 'remove',
                        method: renewContracts,
                        isStatic: true
                    },
                    {
                        icon: 'mdi-phone-message',
                        label: 'send_sms',
                        resource: resource,
                        action: 'sendSms',
                        behavior: 'remove',
                        method: sendSms,
                        isStatic: true
                    },
                    {
                        icon: 'mdi-view-headline',
                        label: 'contract_flattened_display',
                        resource: 'contract',
                        action: 'create',
                        behavior: 'remove',
                        method: handleView,
                        class: "md-primary",
                        isStatic: true
                    }
                    // {
                    //     icon: 'mdi-email-send-outline',
                    //     label: 'send_email',
                    //     resource: resource,
                    //     action: 'sendEmail',
                    //     behavior: 'remove',
                    //     method: sendEmail,
                    //     isStatic: true
                    //
                    // }
                )
            }
            vm.actions = {
                single: singleAction,
                multiple: multipleAction
            }
        }

        function handleModel() {
            switch (doc_type) {
                case "isInvoice":
                    return "Invoice"
                    break;
                case "isQuotation":
                    return "Quotation"
                    break;
                case "isExpense":
                    return "Expense"
                    break;
                case "isContract":
                    return "Contract"
                    break;
            }
        }

        function goToEdition(obj) {
            switch (doc_type) {
                case 'isQuotation':
                    $state.go('app.billing.quotation-form', {quotationId: obj.id});

                    break;
                case 'isInvoice':
                    $state.go('app.billing.invoice-form', {invoiceId: obj.id})
                    break;
                case 'isPayment':
                    $state.go('app.billing.payment-form', {paymentId: obj.id});
                    break;
                case 'isExpense':
                    $state.go('app.billing.expense-form', {expenseId: obj.id});
                    break;
                case 'isContract':
                    $state.go('app.billing.contract-form', {contractId: obj.id});
                    break;
                case 'isCreditNote':
                    $state.go('app.billing.credit-note-form', {creditNoteId: obj.id});
                    break;
            }
        }

        function deleteInvoice(objs) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                _.forEach(objs, function (obj) {
                    var deleteFunc = null
                    switch (doc_type) {
                        case 'isQuotation':
                            deleteFunc = billingService.deleteQuotation;
                            break;
                        case 'isInvoice':
                            deleteFunc = billingService.deleteInvoice
                            break;
                        case 'isPayment':
                            deleteFunc = billingService.deletePayement;
                            break;
                    }
                    obj['is_valid'] == false && _.isFunction(deleteFunc) ? deleteFunc(obj.id).then(_.isFunction(vm.reset) ? vm.reset : _.noop) : showWarning('impossible_delete');

                })
            });
        }

        function payeInvoice(obj) {
            var objs = Array.isArray(obj) ? obj : [obj];
            var lines = [];
            var beneficiary = null;
            var beneficiary_type = null;
            if (_.every(objs, function (item) {
                return item.beneficiary_type == 'PATIENT' && item.beneficiary_name == objs[0].beneficiary_name;
            })) {
                beneficiary = objs[0].beneficiary;
                beneficiary_type = 'P';
            }
            if (_.every(objs, function (item) {
                return item.beneficiary_type == 'ORGANIZATION' && item.beneficiary_name == objs[0].beneficiary_name;
            })) {
                beneficiary = objs[0].beneficiary;
                beneficiary_type = 'T';
            }

            _.forEach(objs, function (obj) {
                if (!obj['is_paid']) lines.push({
                    total_amount: obj.taxed_amount,
                    paid_doc: _.assign(obj, {_module: 'billing.models', _model: 'Invoice'}),
                    encasement_amount: 0.0,
                    remaining_amount: obj.remaining_amount
                })

            });

            if (lines.length > 0) $state.go('app.billing.payment-form', {
                paymentId: null,
                paymentLines: lines,
                beneficiaryType: beneficiary_type,
                beneficiary: beneficiary,
                "paidDoc": {
                    "_module": "billing.models",
                    "_model": "Invoice",
                    "modalModel": "billing.Invoice"
                }
            });
        }

        function showWarning(txt) {
            var simpleToast = $mdToast.simple()
                .textContent($translate.instant(txt))
                .position("top right")
                .hideDelay(3000);
            $mdToast.show(simpleToast);
        }

        function print(obj) {
            switch (doc_type) {
                case 'isQuotation':
                    printService.printItem('quotation', obj.id, null, null);

                    break;
                case 'isInvoice':
                    printService.printItem('invoice', obj.id, null, null);
                    break;
                case 'isPayment':
                    printService.printItem('payment', obj.id, null, null);
                    break;
                case 'isExpense':
                    printService.printItem('expense', obj.id, null, null);
                    break;
                case 'isContract':
                    printService.printItem('contract', obj.id, null, null);
                    break;
                case 'isCreditNote':
                    printService.printItem('billing-credit-note', obj.id, null, null);
                    break;
            }
        }

        function addClick(type) {
            $state.go("app.billing.contract-form", {type: type, contractId: null});
        }

        function billContract(obj) {
            let objs = Array.isArray(obj) ? obj : [obj];
            if (!Array.isArray(obj)) {
                $state.go("app.billing.invoice-form", {
                    invoiceId: null,
                    visits: [obj],
                    beneficiary: obj.beneficiary,
                    beneficiaryType: 'PATIENT',
                    isContract: true,
                    invoiceDate: _.get(obj, 'next_bill_period')
                });
            } else {
                vm.promise = billingService.generateInvoices(_.chain(obj).filter({is_valid: true}).map('id').value()).then(
                    res => {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .parent($(document.body))
                                .clickOutsideToClose(true)
                                .multiple(true)
                                .title('')
                                .htmlContent(`${res} ${$translate.instant('contract_billing_success')}`)
                                .ariaLabel('contracts billing')
                                .ok($translate.instant('confirm'))
                        ).then(vm.reset)
                    }
                )
            }
        }

        function patientResume(obj, ev) {
            frontDeskService.entryResume({
                'patient-id': _.get(obj, 'beneficiary.id'),
                isContract: true,
                onlyResume: true
            }, ev);

        }

        function exportCustomXls(data, query) {
            let columns = [
                {"label": "mle"},
                {"label": "last_name"},
                {"label": "first_name"},
                {"label": "taxed_amount"},
                {'label': 'ad_alone'},
                {'label': 'couple'},
                {'label': 'ch_0'},
                {'label': 'ch_1'},
                {'label': 'ch_2'},
                {'label': 'insurances'},
                {'label': 'remaining_amount'},
                {'label': 'phone_number'},
                {'label': 'email'},
                {'label': 'default_insurance'},
                {'label': 'tariff'},
                {'label': 'end_date'}

            ];

            let translatedColumns = _.reduce(columns, reduceFunc, {
                file_name: $translate['instant']("SUBSCRIPTION"),
                page_name: $translate['instant']('table_export_excel_page_name')
            });

            let q = _.assign({
                translated_columns: translatedColumns,
            }, _.pick(query, ['search', 'search_all', 'filter']));

            vm.promise = billingService.generateCustomXls('billing.Contract', q, null)
                .then(success);

            function reduceFunc(items, item) {
                return _.set(items, item.label, $translate['instant'](item.label));
            }

            function success(data) {
                data.download();
            }
        }

        function contractHistoric(row) {
            billingService.contractHistoric(row).then(done, _.noop);

            function done(data) {
                if (data) {
                    vm.reset();
                }
            }
        }

        function handleSequenceChange() {
            if (vm.sequenceModel == 'all') {
                vm.filter = {is_deleted: {$ne: true}};
            } else {
                vm.filter['sequence_name'] = vm.sequenceModel;
            }
            vm.reset();
        }

        function renewContracts(data, query) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('renew_warning'))
                    .ariaLabel('renew_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                vm.promise = billingService.renewContracts('billing.Contract', _.pick(query, ['search', 'search_all', 'filter']), null)
                    .then(res => {
                        vm.reset();

                        $mdDialog.show(
                            $mdDialog.alert()
                                .parent($(document.body))
                                .clickOutsideToClose(true)
                                .multiple(true)
                                .title('')
                                .htmlContent(`${_.get(res, 'succeeded')} ${$translate.instant('contract_renewed')} - ${_.get(res, 'failed')} ${$translate.instant('contract_renew_failed')}`)
                                .ariaLabel('contracts renewed')
                                .ok($translate.instant('confirm'))
                        ).then(() => {
                        })
                    });
            })
        }

        function addComment(obj) {
            $mdDialog.show(_.assign({}, require('stock/dialogs/comment-preview-dialog'), {
                stockDocument: obj,
                documentCls: 'Contract'
            })).then(success);

            function success(hasComment = false) {
                if (hasComment) {
                    _.isFunction(vm.reset) ? vm.reset() : _.noop();
                }

            }
        }

        function sendSms(list, query) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant(list.length > 0 ? 'selected_send_sms_warning' : 'all_send_sms_warning'))
                    .ariaLabel(list.length > 0 ? 'selected_send_sms_warning' : 'all_send_sms_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                externalConnectionService.showSMSDialog(null, "billing.Contract.handle_contract_sms",
                    list.length > 0 ? _.map(list, 'id') : _.pick(query, ['search', 'search_all', 'filter']),
                    [
                        {
                            label: "full_name",
                            key: 'patient::fullname',
                            path: 'beneficiary.full_name'
                        },
                        {
                            label: "contract_end_at",
                            key: 'contract::end_at',
                            path: 'contract.end_at',
                        },
                        {
                            label: "days_before_expiration",
                            key: 'contract::days_before_expiration',
                            path: 'contract.days_before_expiration',
                        }
                    ]);
            });
        }

        function handlePointing(obj) {
            if (!vm.preventPointing) {
                vm.preventPointing = true;
                if (Array.isArray(obj)) {
                    if (_.every(obj, item => _.get(item, 'pointing_status.label') === 'pointing_active') || _.every(obj, item => _.get(item, 'pointing_status.label') === 'pointing_inactive')) {
                        billingService.handleContractPointing(_.map(obj, 'beneficiary.id'), _.map(obj, 'current_instance.id')).then(after_pointing, after_pointing);
                    } else {
                        showWarning('contract_has_not_same_pointing_status');
                    }
                } else {
                    if (obj.current_instance) billingService.handleContractPointing(obj.beneficiary.id, obj.current_instance.id).then(after_pointing, after_pointing);
                    else {
                        vm.preventPointing = false;
                        showWarning('contract_has_no_current_instance');
                    }
                }
            }

            function after_pointing(data) {
                vm.preventPointing = false;
            }
        }

        function handleView(r) {
            vm.contractDefaultView = vm.contractDefaultView === 'flattened_list' ? 'contract_default_list' : 'flattened_list';
        }

        function handleGuestPointing(obj) {
            $mdDialog.show(_.assign({}, require('billing/dialogs/contract-guest-dialog'), {
                locals: {
                    current_pointing: obj.current_pointing,
                    guests: _.cloneDeep(obj.guests)
                }
            })).then(_.noop);
        }

        function handlePointingHistoric(obj) {
            $mdDialog.show(_.assign({}, require('billing/dialogs/contract-pointing-historic-dialog'), {locals: {beneficiary: obj.beneficiary.id}})).then(_.noop);
        }

        function handleGuestDetails() {
            $mdDialog.show(_.assign({}, require('billing/dialogs/contract-guest-dialog'), {
                locals: {}
            })).then(_.noop);
        }
    }
})()
