/**
 * Created by Hp on 04/05/2017.
 */
(function () {
    'use strict';
    module.exports = billingService;

    billingService.$inject = ["$q", "$http", "mnWebSocket", "$state", "system", "$mdDialog", "$translate"];

    function billingService($q, $http, mnWebSocket, $state, system, $mdDialog, $translate) {
        let self = this;
        let dateFormat = system['date_format'].js;

        self.saveInvoice = saveInvoice;
        self.getInvoice = getInvoice;
        self.deleteInvoice = deleteInvoice;

        self.invalidateInvoice = invalidateInvoice;

        self.saveQuotation = saveQuotation;
        self.getQuotation = getQuotation;
        self.deleteQuotation = deleteQuotation;
        self.invalidateQuotation = invalidateQuotation;

        self.getVisitNonInvoicedProcedure = getVisitNonInvoicedProcedure;
        self.getPlanNonInvoicedProcedure = getPlanNonInvoicedProcedure;

        self.billingVisit = billingVisit;
        self.GenerateVisitQuotation = GenerateVisitQuotation;

        self.getExpense = getExpense;
        self.invalidateExpense = invalidateExpense;
        self.deleteExpense = deleteExpense;
        self.saveExpense = saveExpense;
        self.getDentalVisits = getDentalVisits;
        self.getDentalNonInvoicedProcedure = getDentalNonInvoicedProcedure;
        self.saveContract = saveContract;
        self.updateContract = updateContract;
        self.getContract = getContract;
        self.generateInvoices = generateInvoices;
        self.handleNextBillPeriod = handleNextBillPeriod;
        // self.handleNextPaymentPeriod = handleNextPaymentPeriod;
        self.deleteContract = deleteContract;
        self.updateBillingDocument = updateBillingDocument;
        self.getPatientContract = getPatientContract;
        self.getPatientFullContract = getPatientFullContract;
        self.goToContract = goToContract;
        self.payContract = payContract;

        self.handleContractInstance = handleContractInstance;
        self.renewContract = renewContract;
        self.contractHistoric = contractHistoric;
        self.getContractInstances = getContractInstances;
        self.updateContractInstance = updateContractInstance;
        self.generateCustomXls = generateCustomXls;
        self.renewContracts = renewContracts;

        self.removeContractInstance = removeContractInstance;
        self.deleteContractInstance = deleteContractInstance;
        self.generate_stock_document = generate_stock_document;

        self.savePec = savePec;
        self.getPec = getPec;
        self.deletePec = deletePec;
        self.saveCreditNote = saveCreditNote;
        self.getCreditNote = getCreditNote;
        self.deleteCreditNote = deleteCreditNote;
        self.handleContractPointing = handleContractPointing;


        function saveInvoice(invoice) {
            let deferred = $q.defer();
            let url = `/api/invoice/${!_.isNil(invoice.id) ? invoice.id + "/" : ""}`;
            $http.post(url, invoice).then(successCallback, errorCallback);


            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getInvoice(invoice_id) {
            let deferred = $q.defer();
            let url = '/api/invoice/' + invoice_id + "/";

            $http.get(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteInvoice(invoice_id) {
            let deferred = $q.defer();
            let url = '/api/invoice/' + invoice_id + "/";

            $http.delete(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function invalidateInvoice(invoice_id) {
            let deferred = $q.defer();

            let url = '/api/invoice/' + invoice_id + "/";

            $http.put(url, {is_valid: false}).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function updateBillingDocument(_id, _model, data) {
            let deferred = $q.defer();

            let url = `/api/${_model}/${_id}/`;

            $http.put(url, data).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getVisitNonInvoicedProcedure(_visit_id, _invoice_id) {
            return mnWebSocket.call("billing.Invoice.get_non_invoiced_visit_procedure", {
                visit_id: _visit_id,
                invoice_id: _invoice_id
            });
        }

        function getPlanNonInvoicedProcedure(_plan_id, _invoice_id) {
            return mnWebSocket.call("billing.Invoice.get_non_invoiced_plan_procedure", {
                plan_id: _plan_id,
                invoice_id: _invoice_id
            });
        }

        function getDentalNonInvoicedProcedure(visit_id, _invoice_id) {
            return mnWebSocket.call("billing.Invoice.get_non_invoiced_dental_procedure", {
                visit_id: visit_id,
                invoice_id: _invoice_id
            });
        }

        function saveQuotation(quotation) {
            let deferred = $q.defer();
            let url = `/api/quotation/${!_.isNil(quotation.id) ? quotation.id + "/" : ""}`
            $http.post(url, quotation).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getQuotation(quotation_id) {
            let deferred = $q.defer();
            let url = '/api/quotation/' + quotation_id + "/";

            $http.get(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteQuotation(quotation_id) {
            let deferred = $q.defer();
            let url = '/api/quotation/' + quotation_id + "/";

            $http.delete(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function invalidateQuotation(quotation_id) {
            let deferred = $q.defer();

            let url = '/api/quotation/' + quotation_id + "/";

            $http.put(url, {is_valid: false}).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function saveExpense(expense) {
            let deferred = $q.defer();
            let url = `/api/expense/${_.has(expense, 'id') ? expense.id + '/' : ''}`;
            $http.post(url, expense).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getExpense(expense_id) {
            let deferred = $q.defer();
            let url = `/api/expense/${expense_id}/`;

            $http.get(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteExpense(expense_id) {
            let deferred = $q.defer();
            let url = `/api/expense/${expense_id}/`;

            $http.delete(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function invalidateExpense(expense_id) {
            let deferred = $q.defer();

            let url = `/api/expense/${expense_id}/`;

            $http.put(url, {is_valid: false}).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function billingVisit(objs) {
            if (_.isArray(objs)) {
                let beneficiary = null;
                let beneficiaryType = null;
                if (_.every(objs, {patient: objs[0].patient})) {
                    beneficiary = objs[0].patient;
                    beneficiaryType = 'PATIENT';
                } else {
                    if (_.every(objs, function (item) {
                        return item.patient['default_insurance'] == objs[0].patient['default_insurance']
                    })) {
                        beneficiary = objs[0].patient['default_medical_insurances'].organization;
                        beneficiaryType = 'ORGANIZATION';
                    }
                }
                $state.go("app.billing.invoice-form", {
                    invoiceId: null,
                    visits: objs,
                    beneficiary: beneficiary,
                    beneficiaryType: beneficiaryType
                });
            } else {
                $state.go("app.billing.invoice-form", {
                    invoiceId: null,
                    visits: [objs],
                    beneficiary: objs.patient,
                    beneficiaryType: 'PATIENT'
                });
            }
        }

        function GenerateVisitQuotation(obj) {
            $state.go("app.billing.quotation-form", {
                visit: obj,
                beneficiary: obj.patient,
                beneficiaryType: 'PATIENT'
            });
        }

        function getDentalVisits(query) {
            return mnWebSocket.call("billing.DentalVisitBillingState.dental_visit_list", query);
        }

        function saveContract(contract) {
            let deferred = $q.defer();
            let url = `/api/contract/${!_.isNil(contract.id) ? contract.id + "/" : ""}`
            $http.post(url, _.assign(contract, {ignore: true})).then(successCallback, errorCallback);


            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


        function updateContract(pk, data) {
            let deferred = $q.defer();

            let url = `/api/contract/${pk}/`;
            $http.put(url, _.assign(data, {ignore: true})).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getContract(contract_id) {
            let deferred = $q.defer();
            let url = `/api/contract/${contract_id}/`;

            $http.get(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function generateInvoices(ids) {
            return mnWebSocket.call('billing.Invoice.generate_invoices', {ids});
        }

        function handleNextBillPeriod(ids, decrement = false) {
            return mnWebSocket.call('billing.Contract.update_bill_period', {ids, decrement});
        }

        // function handleNextPaymentPeriod(ids, decrement = false) {
        //     return mnWebSocket.call('billing.Contract.update_payment_period', {ids, decrement});
        // }

        function deleteContract(contract_id) {
            let deferred = $q.defer();
            let url = '/api/contract/' + contract_id + "/";

            $http.delete(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteContractInstance(pk) {
            let deferred = $q.defer();
            let url = '/api/contract-instance/' + pk + "/";

            $http.delete(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getPatientContract(patient) {
            return mnWebSocket.call('billing.Contract.get_patient_contract', {patient});
        }

        function getPatientFullContract(patient) {
            return mnWebSocket.call('billing.Contract.get_patient_full_contract', {patient});
        }

        function goToContract(patient) {
            getPatientContract(patient.id).then(function success(data) {
                if (data) {
                    $state.go('app.billing.contract-form', {
                        contractId: data,
                        "duplicateObject": null
                    }, {location: 'replace'});
                } else {
                    $state.go('app.billing.contract-form', {
                        contractId: null,
                        duplicateObject: {
                            beneficiary: patient,
                            start_at: moment().format(dateFormat),
                            doc_date: moment().format(dateFormat),
                            adhesion_date: moment().format(dateFormat),
                            folder_number: _.get(patient, 'file_number'),
                            billing_details: {
                                bill_period: 'YEAR',
                                bill_nbr: 1,
                                auto_renew: true,
                                renewal_nbr: 1,
                                renewal_period: 'YEAR',
                                free_trial: 0,
                                automatic_close_limit: 0,
                                expired_cycles: 1
                            },
                            details: [],
                            type: "SUBSCRIPTION",
                            auto_renew: true,
                            tariff: patient.tariff

                        }
                    }, {location: 'replace'});
                }
            })
        }

        function payContract(obj) {
            let objs = Array.isArray(obj) ? obj : [obj];
            let lines = [];
            if (!_.isEmpty(objs)) {
                $state.go('app.billing.payment-form', {
                    paymentId: null,
                    paymentLines: [],
                    beneficiaryType: 'P',
                    beneficiary: objs[0].beneficiary,
                    "paidDoc": {
                        "_module": "billing.models",
                        "_model": "ContractInstance",
                        "_parent": objs[0].id,
                        "_received_amount": _.get(objs[0], 'current_instance.amount_to_pay', 0) || _.get(objs[0], 'amount_to_pay', 0),
                        "modalModel": "billing.ContractInstance"
                    }
                });
            }
        }

        function handleContractInstance(pk) {
            return mnWebSocket.call("billing.Contract.handle_contract_instance", {pk});
        }

        function renewContract(obj, details) {
            let deferred = $q.defer();
            let newDetails = null;
            const dialog = _.assign({}, require('billing/dialogs/contract-renew-dialog.js'), {
                locals: {
                    PrevEndDate: obj.current_instance.end_at,
                    operator: obj.operator,
                    instance: _.cloneDeep({details})
                }
            });

            $mdDialog.show(dialog).then(done, errorCallback);

            function done(data) {
                newDetails = data
                return mnWebSocket.call("billing.Contract.renew_contract_instance", {
                    pk: obj.id,
                    start_at: _.get(data, 'start_at')
                }).then(success);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            function success(data) {

                self.updateContractInstance(_.get(data, 'pk'), {
                    operator: newDetails.operator,
                    details: _.get(newDetails, 'details'),
                    taxed_amount: _.get(newDetails, 'taxed_amount')
                }).then(ci => {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .parent($(document.body))
                            .clickOutsideToClose(true)
                            .multiple(true)
                            .title('')
                            .htmlContent(`${$translate.instant('contract_valid_until')} ${_.get(data, 'start_date')}`)
                            .ariaLabel('contract_valid_until')
                            .ok($translate.instant('confirm'))
                    ).then(() => {
                        let allow_generate = _.get(data, 'allow_generate');
                        getContract(obj.id).then((c) => {
                            if (allow_generate) {
                                generate_stock_document(obj, obj.current_instance);
                            }
                            obj = c;
                            deferred.resolve(c);
                        });
                    });
                })


            }

            return deferred.promise;

        }

        function contractHistoric(obj) {
            const dialog = _.assign({}, require('billing/dialogs/contract-historic-dialog.js'), {
                locals: {
                    instanceParent: obj.id,
                    parentCurrentInstance: _.get(obj, 'current_instance.id')
                }
            });

            return $mdDialog.show(dialog);
        }

        function getContractInstances(pk) {
            return mnWebSocket.call("billing.Contract.get_contract_instances", {pk})
        }

        function updateContractInstance(pk, data) {
            let deferred = $q.defer();

            let url = `/api/contract-instance/${pk}/`;

            $http.put(url, data).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function generateCustomXls(model, query) {
            return mnWebSocket.call(`${model}.generate_custom_excel`, query);
        }

        function renewContracts(model, query) {
            return mnWebSocket.call(`${model}.renew_multi_contract`, query);
        }

        function removeContractInstance(parent_pk, pk) {
            return mnWebSocket.call("billing.Contract.remove_last_instance", {parent_pk, pk});
        }

        function generate_stock_document(c, ci) {
            $mdDialog.show(_.assign(require('stock/dialogs/generate-stock-doc-dialog'), {
                targetEvent: null,
                locals: {
                    model: {
                        price_mode: 'HT',
                        doc_date: moment().format(dateFormat),
                        type: 'OUT',
                        patient: c.beneficiary,
                        return_date: ci.end_at,
                        start_date: ci.start_at,
                        related_doc: ci,
                        lines: _.map(ci.details, (l) => {
                            return {
                                code: l.code,
                                article: l.article,
                                qte: l.qte,
                                unit_price: l.price,
                                short_desc: l.description,
                                is_comment: _.get(l.is_comment, false)
                            }
                        })
                    }
                }
            })).then(_.noop);
        }

        function savePec(pec) {
            let deferred = $q.defer();

            let url = `/api/pec/${!_.isNil(pec.id) ? pec.id + "/" : ""}`;
            $http.post(url, pec).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getPec(pec_id) {
            let deferred = $q.defer();
            let url = '/api/pec/' + pec_id + "/";

            $http.get(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deletePec(pec_id) {
            let deferred = $q.defer();
            let url = '/api/pec/' + pec_id + "/";

            $http.delete(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


        function saveCreditNote(doc) {
            let deferred = $q.defer();

            let url = `/api/billing-credit-note/${!_.isNil(doc.id) ? doc.id + "/" : ""}`;
            $http.post(url, doc).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getCreditNote(pk) {
            let deferred = $q.defer();

            let url = `/api/billing-credit-note/${pk}/`;
            $http.get(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteCreditNote(pk) {
            let deferred = $q.defer();

            let url = `/api/billing-credit-note/${pk}/`;
            $http.delete(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function handleContractPointing(beneficiaries, instances) {
            return mnWebSocket.call("billing.Contract.handle_contract_pointing", {beneficiaries, instances});
        }

    }
})();